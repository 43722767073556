import React, { useState } from "react"
import PasswordlessLinkVerify from '../components/auth0/PasswordLessLinkVerify';


const LoginFormLink = (props) => {


  return (
    
      <PasswordlessLinkVerify login={true} />

  )
}

export default LoginFormLink
