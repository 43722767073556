import React, {Component} from 'react';
import auth0 from 'auth0-js';

class PasswordlessLinkVerify extends Component {
  constructor(props) {
    super(props);

    let qsvalue = null;
    let evalue = "";
    let cvalue = "";

    this.state = {
      email: '',
      code: '',
      showCodeForm: false, // Initially hide the code form
      isAuthenticated: false, // Initially assume the user is not authenticated
      error: null,
      returnUrl: qsvalue,
    };

    if (typeof window !== 'undefined') {
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });

      qsvalue = localStorage.getItem('returnUrl');

      if ((typeof params.email != "undefined") && (params.email != null)) {

        evalue = params.email.replace(' ', '+');
        this.state.email = evalue;

      }

      if ((typeof params.cd != "undefined") && (params.cd != null)) {

        cvalue = params.cd;
        this.state.code = cvalue;

      }

      const redirectUri = process.env.AUTH0_REDIRECT_URI + "?returnUrl=" + qsvalue;

      localStorage.removeItem('returnUrl');

      // Configure Auth0
      this.auth0 = new auth0.WebAuth({
        domain: process.env.AUTH0_DOMAIN,
        clientID: process.env.AUTH0_CLIENTID,
        responseType: 'token id_token',
        redirectUri: redirectUri,
        scope: 'openid email' // Add any additional scopes you need
      });

      const linkStart = () => {
        this.auth0.passwordlessLogin({
          connection: 'email',
          email: evalue,
          verificationCode: cvalue,
          responseType: 'token id_token',
          scope: 'openid email',
          prompt: 'none',
        }, (error, result) => {
          if (error) {
            this.setState({ error: error.description });

            //console.log("linkVerify", error, evalue, cvalue)

            window.location.href = '/login';
          } else {
            // Set the isAuthenticated state to true
            this.setState({ isAuthenticated: true });

            // Redirect to the account page
            if ((this.state.returnUrl != "") && (this.state.returnUrl != null) && (this.state.returnUrl != "null") && (typeof this.state.returnUrl != "undefined")) {

              if (typeof window !== 'undefined') {
                window.location.href = this.state.returnUrl;
              }
              else {
                window.location.href = '/members';
              }
            }
            else {
              window.location.href = '/members';
            }


          }
        });
      }

      if ((evalue != null) && (evalue != "")) {
       
          linkStart();
             
      }

    }

  }

  render() {
    return null;
  }
    
}


export default PasswordlessLinkVerify;
